// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2025 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { isHydrationUntouched } from "../Hydratable/utils";
import { HydratorProps } from "./types";

/**
 * Observes the provided `hydratable` and renders `children` immediately.
 * Also initiates hydration of the model if it is not already pending.
 */
export const HydratorWithDirectHydration: React.FC<HydratorProps> = observer(
  function Hydrator({ children, hydratable }) {
    const needsHydration = isHydrationUntouched(hydratable);

    useEffect(() => {
      if (needsHydration) {
        hydratable.hydrate();
      }
    }, [hydratable, needsHydration]);

    return children;
  },
);
